<template>
  <div>
    <b-card>
      <div class="mb-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="$can('read', 'Admin')"
              variant="primary"
              :to="{ name: 'apps-add-product' }"
            >
              Add Product
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end"></div>
          </b-col>
        </b-row>
      </div>
      <b-table :fields="fields" :items="items" responsive="sm">
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(name)="data">
          <a @click="editItem(data)" style="color: #ef4137">{{ data.item.name }}</a>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            v-if="selected && selected.product_id == data.item.product_id"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="editItem(data)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <div class="text-center loader" v-if="isLoading">Loading...</div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BProgress,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BModal,
  BCardText,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { getAllProducts } from "../../../../aws/Api";
import store from "@/store";
export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BDropdown,
    BModal,
    BCardText,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: "index", label: "#" },
        // A column that needs custom formatting
        { key: "name", label: "Name" },
        // A regular column
        { key: "tier", label: "Tier" },
        // A virtual column made up from two fields
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "version", label: "Version" },
      ],
      search: "",
      backup: [],
      selected: null,
      isLoading: false,
    };
  },
  // computed
  computed: {
    userInfo: function () {
      return store.state["app-users"].userInfo;
    },
    items: function () {
      const products = store.state["app-products"].products;
      if (this.userInfo.user__role == "admin") {
        return products;
      } else {
        return [
          ...products.filter((item) => item.price == "0"),
          ...products.filter((item) => item.payment_confirmed == "true"),
        ];
      }
    },
  },
  // mounted
  async mounted() {
    if (!this.userInfo) return (this.isLoading = false);
    try {
      this.isLoading = true;
      const response = await getAllProducts(this.userInfo.user__id);
      store.dispatch("app-products/SAVE_PRODUCTS", [
        ...store.state["app-products"].products,
        ...response,
      ]);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },
  // method
  methods: {
    // selected item
    selectedItem(data) {
      this.selected = data.item;
    },
    // edit data
    editItem(event) {
      store.dispatch("app-products/SAVE_SELECTED_PRODUCT", event.item);
      if (this.userInfo.user__role === "admin") {
        this.$router.push({
          name: "apps-edit-product",
          params: { slug: event.item.product_id },
        });
      } else {
        this.$router.push({
          name: "apps-products-details",
          params: { slug: event.item.product_id },
        });
      }
    },
    // fetch items
  },
};
</script>

<style lang="css">
.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>